import { useZodForm } from "ui/use-zod-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "ui/form";
import { Alert, AlertDescription } from "ui/alert";
import { Input } from "ui/input";
import z from "zod";
import Link from "next/link";
import { Button } from "ui/button";
import { signIn } from "auth-astro/client";
import { useState } from "react";

const schema = z.object({
  email: z
    .string({
      required_error: "Vul een geldig e-mailadres in.",
      invalid_type_error: "Vul een geldig e-mailadres in.",
    })
    .email("Vul een geldig e-mailadres in."),
  password: z.string({
    required_error: "Vul een wachtwoord in.",
    invalid_type_error: "Vul een wachtwoord in.",
  }),
});

type FormValues = z.infer<typeof schema>;

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const form = useZodForm(schema);

  const onSubmit = async (formData: FormValues) => {
    setLoading(true);

    const redirectUrl =
      new URLSearchParams(window.location.search).get("redirect_url") || "/";

    const data = await signIn("credentials", {
      redirect: false,
      email: formData.email,
      password: formData.password,
      callbackUrl: redirectUrl,
    }).then((res) => res?.json());

    if ("url" in data && data.url.includes("credentials")) {
      setLoading(false);

      return form.setError("root", {
        message: "E-mailadres en/of wachtwoord is onjuist.",
      });
    }

    window.location.href = "/";
  };

  return (
    <Form {...form}>
      <form
        className="my-2 grid grid-cols-1 gap-4 px-5"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        {form.formState.errors.root !== undefined && (
          <Alert variant="destructive">
            <AlertDescription>
              {form.formState.errors.root.message}
            </AlertDescription>
          </Alert>
        )}

        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">E-mailadres</FormLabel>
              <FormControl>
                <Input type="email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="font-normal">Wachtwoord</FormLabel>
              <FormControl>
                <Input type="password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Link href="/auth/forgot-password">Wachtwoord vergeten</Link>

        <Button disabled={loading}>Inloggen</Button>
      </form>
    </Form>
  );
};

export { LoginForm };
